import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import CookieConsent from "react-cookie-consent";

const TemplateWrapper = ({children}) => {
    const {title, description} = useSiteMetadata()
    return (
        <div>
            <Helmet>
                <html lang="de"/>
                <title>{title}</title>
                <meta name="description" content={description}/>

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon-16x16.png`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff"/>

                <meta property="og:type" content="business.business"/>
                <meta property="og:title" content={title}/>
                <meta property="og:url" content="/"/>
                <meta
                    property="og:image"
                    content={`${withPrefix('/')}img/spiele/spiele-zu-zweit-title.jpg`}
                />
            </Helmet>
            <Navbar/>
            <div>{children}</div>
            <Footer/>
            <Helmet>
                <script defer src='https://static.cloudflareinsights.com/beacon.min.js'
                        data-cf-beacon='{"token": "f96ceafa9e2f4cdaa5136b3b999e5699"}' />
            </Helmet>
        </div>
    )
}

export default TemplateWrapper;
