import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer has-background-black has-text-white-ter">
                <div className="content has-text-centered">
                    <span className="is-size-4" style={{color: '#ff4400'}}>Spiele zu zweit</span>
                </div>
                <div className="content has-text-centered has-background-black has-text-white-ter">
                    <div className="container has-background-black has-text-white-ter">
                        <div style={{maxWidth: '100vw'}} className="columns">
                            <div className="column is-4">
                                <section className="menu">
                                    <ul className="menu-list">
                                        <li>
                                            <Link to="/" className="navbar-item">
                                                Startseite
                                            </Link>
                                            <Link className="navbar-item" to="/blog">
                                                Blog
                                            </Link>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className="column is-4">
                                <section>
                                    <ul className="menu-list">
                                        <li>
                                            <Link className="navbar-item" to="/impressum">
                                                Impressum
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-item" to="/datenschutz">
                                                Datenschutz
                                            </Link>
                                        </li>
                                    </ul>
                                </section>
                            </div>

                            <div className="column is-4">
                                <span>Email: kontakt@spiele-zu-zweit.de</span>
                                <p className="small-bottom-text">
                                    *Liebe Freunde. Um weitere Spiele testen zu können, finanziere ich mein Hobby durch
                                    die Verwendung von Affiliate Links. Ich bekomme daher im Falle eines Kaufs eine
                                    kleine Provision vom jeweiligen Partnershop. Für dich entstehen dadurch natürlich
                                    keine Kosten. Ich danke dir vielmals für deine Unterstützung.
                                </p>
                            </div>
                            {/*<div className="column is-4 social">
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>*/}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
